import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class EstimationResult {
  @JsonProperty() price!: number | string

  @JsonProperty() title!: string

  @JsonProperty() unit!: string

  @JsonProperty() value!: number

  @JsonProperty() type!: string
}
