


















































































































import Component, { mixins } from "vue-class-component"
import TabMixin from "@/types/TabMixin"
import CalculateForm from "@/components/Calculate/CalculateForm.vue"
import EstimationApi from "@/api/Estimation/EstimationApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import EstimationProductGroup from "@/types/Estimation/EstimationProductGroup"
import EstimationProductCategory from "@/types/Estimation/EstimationProductCategory"
import { Watch } from "vue-property-decorator"
import BreakpointMixin from "../../types/BreakpointMixin"
import InputMixin from "../../types/InputMixin"

@Component({
  name: "CalculateDetail",
  components: { CalculateForm }
})
export default class CalculateDetail extends mixins(InputMixin, BreakpointMixin, TabMixin) {
  private productCategories: EstimationProductCategory[] = []

  private productGroups: EstimationProductGroup[] = []

  private groupId = 1

  private categoryId = 1

  private get tab() {
    return _.findIndex(this.productGroups, ["productGroupId", this.groupId])
  }

  private get subTab() {
    return _.findIndex(this.productCategories, ["productCategoryId", this.categoryId])
  }

  private changeTab(groupId: number) {
    const lang = this.$i18n.locale
    this.$router.replace({
      name: `${this.$route.name}`,
      query: { group: `${groupId}`, lang }
    })
  }

  private generateRoute(groupId: number) {
    return {
      name: `${this.$route.name}`,
      query: { group: `${groupId}`, lang: this.$i18n.locale }
    }
  }

  @Watch("groupId")
  onGroupIdChange() {
    this.fetchCategory()
  }

  private changeSubTab(categoryId: number) {
    const lang = this.$i18n.locale

    this.$router.replace({
      name: `${this.$route.name}`,
      query: { group: `${this.groupId}`, category: `${categoryId}`, lang }
    })
  }

  private generateSubRoute(categoryId: number) {
    return {
      name: `${this.$route.name}`,
      query: { group: `${this.groupId}`, category: `${categoryId}`, lang: this.$i18n.locale }
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    // @ts-ignore
    this.groupId = parseInt(this.$route.query.group, 10) || 1
    // @ts-ignore
    this.categoryId = parseInt(this.$route.query.category, 10) || -1
    this.fetchData()
  }

  private fetchData() {
    this.loadData()
  }

  private mounted() {
    // @ts-ignore
    this.groupId = parseInt(this.$route.query.group, 10) || 1
    // @ts-ignore
    this.categoryId = parseInt(this.$route.query.category, 10) || -1
    this.fetchData()
  }

  private loadData() {
    return this.fetchProductGroups().then(this.fetchCategory)
  }

  private fetchProductGroups() {
    return EstimationApi.listProductGroups().then(({ data }) => {
      this.productGroups = _.map(data, (d) => deserialize<EstimationProductGroup>(d, EstimationProductGroup)) || []
    })
  }

  private fetchCategory() {
    if (this.groupId <= 0) return new Promise(console.log)
    return EstimationApi.listProductCategories(this.groupId)
      .then(({ data }) => {
        this.productCategories =
          _.map(data, (d) => deserialize<EstimationProductCategory>(d, EstimationProductCategory)) || []
      })
      .then(() => {
        if (this.categoryId === -1) {
          this.categoryId = _.first(this.productCategories)?.productCategoryId || 1
        }
      })
  }

  private getCategoryTabClass(idx: string) {
    return this.getTabClass(idx, this.subTab)
  }

  private getGroupTabClass(idx: string) {
    return this.getTabClass(idx, this.tab)
  }

  // eslint-disable-next-line class-methods-use-this
  private getTabClass(idx: string, comparator: number) {
    const nidx = parseInt(idx, 10)
    let cls = ""
    if (nidx === 0) {
      cls = cls.concat("pl-6 ml-16 ")
    }
    if (comparator === nidx) {
      cls = cls.concat("custom-active-tab font-weight-semi-bold black--text")
    } else {
      cls = cls.concat("font-weight-light black--text")
    }
    return cls
  }
}
