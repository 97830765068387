import { JsonProperty, Serializable } from "typescript-json-serializer"
import EstimationShapeFieldItem from "@/types/Estimation/EstimationShapeFieldItem"
import DiamondFile from "@/types/DiamondFile"
import EstimationColor from "@/types/Estimation/EstimationColor"

@Serializable()
export default class EstimationShapeField {
  @JsonProperty({ type: EstimationShapeFieldItem }) field!: Array<EstimationShapeFieldItem>

  @JsonProperty({ type: DiamondFile }) file?: DiamondFile

  @JsonProperty({ type: EstimationColor }) thickness?: EstimationColor
}
